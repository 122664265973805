html {
  box-sizing: border-box;
  font-size: 13px;
  word-break: keep-all;
  color: #333e48;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Apple SD Gothic Neo', Dotum, Arial, sans-serif;
  line-height: 1.618;
  -webkit-font-smoothing: antialiased;
}

body.spoqa-han-sans-loaded {
  font-family: 'Spoqa Han Sans', 'Apple SD Gothic Neo', Dotum, Arial, sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}
